import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Button,
  Chip as MuiChip,
  CircularProgress,
  Fade,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Toolbar,
  Tooltip,
  Typography,
  LinearProgress,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  Description,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material";

import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridState,
  gridPaginationModelSelector,
  useGridApiRef,
  GridRowSelectionModel,
  GridToolbarQuickFilter,
  GridFilterModel,
  GridLogicOperator,
} from "@mui/x-data-grid";
import { spacing } from "@mui/system";

import {
  useDeleteVendorMutation,
  useGetVendorsQuery,
  useLazyGetContractsForVendorQuery,
} from "../../redux/slices/vendorsApiSlice";
import { Delete, LegendToggle } from "@mui/icons-material";
import VendorAvatar from "../avatars/VendorAvatar";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";
import { useTranslation } from "react-i18next";
import EnhancedDataGridToolbar from "./EnhancedDataGridToolbar";
import VendorDialog from "../dialogs/VendorDialog";
import { DialogMode } from "../../types/dialogmode";
import { useSyncVendorWithKVKMutation } from "../../redux/slices/kvkSlice";
import { IVendor } from "../../types/vendor";
import MegaButton from "../../pages/components/MegaButton";
import KvKIcon from "../../icons/KvKIcon";
import { format, set } from "date-fns";
import LoadingButton from "@mui/lab/LoadingButton";
import { AIReadingStatus } from "../../types/contract";
import { skipToken } from "@reduxjs/toolkit/dist/query";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const Chip = styled(MuiChip)<{ color?: string }>`
  height: 24px;
  border-radius: 12px;
  // font-size: 85%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"]}; // .light
  // color: ${(props) => props.theme.palette.common.white};
`;

interface IDataGridVendorsProps {
  view?: "cards" | "table";
  onSelect?: (selected: any) => void;
  selected?: any;
}

function DataGridVendors({ ...props }: IDataGridVendorsProps) {
  const { t } = useTranslation();
  const [view, setView] = useState(props.view || "cards");

  const navigate = useNavigate();

  const {
    data: vendors = [],
    isLoading,
    isSuccess: vendorsLoaded,
  } = useGetVendorsQuery();

  const [reloadAvatar, setReloadAvatar] = useState(false);
  const [syncingKVK, setSyncingKVK] = useState(false);
  const [syncingKVKSuccess, setSyncingKVKSuccess] = useState(false);
  const [syncingNumber, setSyncingNumber] = useState(0);
  const [searchText, setSearchText] = useState<string>("");

  // const [getUserPhotoForUser, { data: photoData }] = useGetUserPhotoForUserMutation({ id: "" });
  // const [displayedUsers, setDisplayedUsers] = useState<IGraphUser[]>([]);
  // const [userPhotos, setUserPhotos] = useState<Record<string, string>>({});

  const [
    syncVendorWithKVK,
    {
      isSuccess: isUpdated,
      isLoading: isUpdating,
      isError: updateError,
      error: updateErrorData,
      data: updatedVendor,
      reset: resetUpdate,
    },
  ] = useSyncVendorWithKVKMutation();

  const apiRef = useGridApiRef();
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);

  const [
    deleteVendor,
    { isLoading: deleting, isSuccess: isDeleted, reset: resetDelete },
  ] = useDeleteVendorMutation();

  const [getContractsForVendor] = useLazyGetContractsForVendorQuery();

  const [title, setTitle] = useState<string>("");
  const [contracts = [], setContracts] = useState<
    {
      vendorId: string;
      contracts: number;
    }[]
  >([]);

  const columns: GridColDef[] = [
    // {
    //   field: "avatar",
    //   headerName: "",
    //   width: 80,
    //   renderCell: (params:any) => {
    //     return (
    //       <Grid container sx={{ maxWidth: 80, maxHeight: 52 }}>
    //         <Grid
    //           item
    //           sx={{
    //             backgroundImage: `url('${params.row.logo}')`,
    //             backgroundSize: "contain",
    //             height: 45,
    //             width: "100%",
    //             backgroundPosition: "center",
    //             backgroundRepeat: "no-repeat",
    //           }}
    //         ></Grid>
    //       </Grid>
    //     );
    //   },
    // },
    {
      flex: 1,
      field: "name",
      headerName: t("Name")!,
      renderCell: (cellValues) => {
        return (
          <Tooltip
            title={
              <Box>
                {cellValues.row?.name}
                <br />
                {cellValues.row?.city}
              </Box>
            }
            placement="top"
          >
            <Typography
              variant="body2"
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {cellValues.row?.analyzeStatus === AIReadingStatus.ANALYZING && (
                <Chip
                  sx={{ mr: 2 }}
                  label={t("Analyzing")}
                  color="primary"
                  variant="outlined"
                />
              )}
              {cellValues.row?.name}
              {/* <VendorAvatar
                vendorId={cellValues?.row?.id}
                small
                refetch={reloadAvatar}
              /> */}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      flex: 1,
      field: "city",
      headerName: t("City")!,
      disableColumnMenu: true,
    },
    {
      flex: 1,
      field: "country",
      headerName: t("Country")!,
      disableColumnMenu: true,
    },
    {
      flex: 1,
      field: "kvkNumber",
      headerName: t("KVK Number")!,
      disableColumnMenu: true,
    },
    {
      flex: 1,
      field: "foundedDate",
      headerName: t("Founded")!,
      disableColumnMenu: true,
      valueFormatter: (params: any) => {
        return params && format(new Date(params), "yyyy");

        //  format(new Date(params), "PP", {
        //    locale: i18n.language === "nl" ? nl : enGB,
        //  });
      },
    },
    {
      flex: 1,
      field: "id",
      headerName: t("Contracts")!,
      disableColumnMenu: true,
      valueFormatter: (params: any) => {
        const contractsCount =
          contracts?.find((el) => el.vendorId === params?.value)?.contracts ||
          0;

        return contractsCount;
      },
      renderCell(params: any) {
        return (
          <Button
            onClick={() => navigate(`/vendor/${params?.value}/contracts`)}
          >
            {params.formattedValue}
          </Button>
        );
      },
    },
    {
      headerName: t("Action")!,
      minWidth: 140,
      field: "action",
      disableColumnMenu: true,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <>
            <IconButton
              aria-label="view"
              onClick={(event) => {
                handleViewDetails(event, cellValues);
              }}
            >
              <RemoveRedEyeIcon />
            </IconButton>

            <VendorDialog
              vendor={cellValues.row}
              mode={DialogMode.Edit}
              iconOnly
            />

            <VendorDialog
              vendor={cellValues.row}
              mode={DialogMode.Delete}
              iconOnly
            />

            {/* <IconButton aria-label="delete" size="large" onClick={(event) => handleDeleteClick(event, cellValues)}>
              <Delete />
            </IconButton> */}
          </>
        );
      },
    },
  ];

  const [errors, setErrors] = useState<any>([]);

  useEffect(() => {
    if (reloadAvatar) {
      // setReloadAvatar(false);
    }
  }, [reloadAvatar]);

  useEffect(() => {
    if (props?.selected) {
      // Set the default selected items
      setRowSelectionModel(props.selected);
    }
  }, []);

  useEffect(() => {
    if (isDeleted) {
      resetDelete();
    }
  }, [isDeleted]);

  useEffect(() => {
    const vendorsText = vendors?.length === 1 ? t("Vendor") : t("Vendors");
    const newTitle = `${vendors?.length} ${vendorsText}`.toLowerCase();
    setTitle(newTitle);
  }, [vendors]);

  useEffect(() => {
    if (vendorsLoaded) {
      vendors.forEach((vendor: IVendor) => {
        getContracts(vendor);
      });

      //  for (const vendor of vendors) {
      //    getContracts(vendor);
      //  }
    }
  }, [vendorsLoaded]);
  // const paginationModel = gridPaginationModelSelector(apiRef.current.state, apiRef.current.instanceId);

  useEffect(() => {
    // this is the id of the selected row/user
    console.log(rowSelectionModel);

    props?.onSelect && props?.onSelect(rowSelectionModel);
  }, [rowSelectionModel]);

  const getContracts = async (vendor: IVendor) => {
    const response = await getContractsForVendor(vendor.id).unwrap();
    console.log(response);

    const info = {
      vendorId: vendor.id,
      contracts: response?.length || 0,
    };

    setContracts((prev) => [...prev, info]);
  };

  const handleGetCompanyProfile = async (vendor: IVendor) => {
    if (!vendor?.kvkNumber) {
      console.log("Vendor does not have a KVK number");

      if (rowSelectionModel?.length === 1) {
        // for a bulk analysis, we don't want to show the error message
        errors.push({ errorMessage: "Vendor does not have a KVK number" });
        setErrors(errors);
      }
    }

    const response = await syncVendorWithKVK(vendor);

    if (response.isError) {
      const message = response.error?.error;
      const htmlError = response.error?.data;

      if (htmlError) {
        errors.push({ errorHtml: htmlError });
      } else {
        errors.push({ errorMessage: message });
      }
      setErrors(errors);
    }
  };

  const handleRowDoubleClick = (params: any) => {
    navigate(`/vendor/${params.row.id}/information`);
  };

  const handleViewDetails = (event: any, cellValues: any) => {
    navigate(`/vendor/${cellValues.row.id}/information`);
  };

  // This function is triggered when the delete button in the toolbar is clicked
  const handleToolbarDeleteClick = (event: any) => {
    console.log(rowSelectionModel);

    rowSelectionModel.forEach((row) => {
      deleteVendor(row.toString());
    });
  };

  const syncWithKVK = () => {
    setErrors([]);
    setSyncingKVK(true);
    setSyncingKVKSuccess(false);

    const promises: Promise<any>[] = [];

    if (rowSelectionModel.length === 0) {
      setSyncingNumber(vendors.length);

      // Sync all vendors
      vendors.forEach((v: IVendor) => {
        promises.push(handleGetCompanyProfile(v));
      });
    } else {
      setSyncingNumber(rowSelectionModel.length);

      rowSelectionModel.forEach((row) => {
        const v = vendors.find((v: IVendor) => v.id === row);
        if (v) {
          promises.push(handleGetCompanyProfile(v));
        }
      });
    }

    Promise.all(promises).then(() => {
      setSyncingKVK(false);
      setSyncingKVKSuccess(true);
      resetUpdate();
    });
  };

  return (
    <Paper mb={16}>
      <Box
        // height="55vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
        }}
      >
        <Paper p={4}>
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            alignItems="center"
          >
            <Grid size={12}>
              <TextField // Search field
                variant="outlined"
                label={t("Search")}
                fullWidth
                value={searchText}
                onChange={(event) => {
                  setSearchText(event?.target?.value);
                }}
                sx={{ mb: 2 }}
              />
            </Grid>

            {(syncingKVK || syncingKVKSuccess) && (
              <Grid size={12}>
                <Box mt={6} mb={4}>
                  {syncingKVK ? (
                    <>
                      <Alert severity="info">
                        <AlertTitle>{t("Analyzing")}</AlertTitle>
                        {t("Syncing_vendors", {
                          count: syncingNumber,
                        })}
                      </Alert>
                      <LinearProgress variant="indeterminate" />
                    </>
                  ) : syncingKVKSuccess && errors?.length === 0 ? (
                    <Alert severity="success">
                      <AlertTitle>{t("Success")}</AlertTitle>
                      {t("Vendors have been synced with KVK")}
                    </Alert>
                  ) : (
                    <span />
                  )}

                  {errors?.length > 0 && (
                    <Grid container spacing={3} direction="column">
                      {errors.map((error: any) => {
                        return (
                          <Grid>
                            <Alert severity="error">
                              <AlertTitle>{t("An error occurred!")}</AlertTitle>

                              {error.errorHtml ? (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: error.errorHtml,
                                  }}
                                />
                              ) : (
                                <>
                                  {error?.data?.message
                                    ? error?.data.message
                                    : error?.message
                                    ? error.message
                                    : error?.data
                                    ? JSON.stringify(error.data)
                                    : JSON.stringify(error)}
                                </>
                              )}
                            </Alert>
                          </Grid>
                        );
                      })}
                    </Grid>
                  )}
                </Box>
              </Grid>
            )}
          </Grid>
        </Paper>

        {isLoading && <CircularProgress sx={{ margin: 4 }} />}

        {vendorsLoaded && vendors?.length === 0 && (
          <Box p={6}>
            <CustomNoRowsOverlay label={t("No vendors")} />
          </Box>
        )}

        {!isLoading && vendorsLoaded && vendors?.length > 0 && (
          <DataGrid
            initialState={{
              pagination: { paginationModel: { page: 0, pageSize: 10 } },
            }}
            apiRef={apiRef}
            pageSizeOptions={[5, 10, 25]}
            rows={vendors}
            columns={columns}
            autoHeight
            checkboxSelection
            loading={isLoading}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            filterModel={{
              items: [
                {
                  id: 1,
                  field: "name",
                  operator: "contains",
                  value: searchText,
                },
              ],
            }}
            slots={{
              toolbar: () => (
                <EnhancedDataGridToolbar
                  rowSelectionModel={rowSelectionModel}
                  handleDeleteClick={handleToolbarDeleteClick}
                  view={view}
                  // setView={setView}
                  title={title}
                  isLoading={isLoading}
                  deleting={deleting}
                  customActions={[
                    <LoadingButton
                      variant="text"
                      onClick={syncWithKVK}
                      startIcon={<KvKIcon />}
                      loading={isUpdating}
                      // disabled={rowSelectionModel.length === 0}
                    >
                      {t("Synchronize company details with KVK")}
                    </LoadingButton>,
                  ]}
                />
              ),
              noRowsOverlay: () => (
                <CustomNoRowsOverlay label={t("No vendors")} />
              ),
            }} // GridToolbar    GridToolbarQuickFilter
            slotProps={{
              toolbar: {
                showQuickFilter: false,
                quickFilterProps: { debounceMs: 500 },
              },
              // show button to jump to the first page
              pagination: {
                showFirstButton: true,
                showLastButton: true,
              },
              // pagination: {
              //   labelRowsPerPage: t("Rows per page"),
              //   labelDisplayedRows(paginationInfo) {
              //     return `${paginationInfo.from}-${paginationInfo.to} ${t("of")} ${paginationInfo.count}`;
              //   },
              // },
            }}
            sx={{
              "& .MuiDataGrid-cell": {
                alignContent: "center",
              },
            }}
            density="comfortable"
            onRowDoubleClick={handleRowDoubleClick}
          />
        )}
      </Box>
    </Paper>
  );
}

export default DataGridVendors;
