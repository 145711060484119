import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { IConnectedDrive } from "../../types/connecteddrive";
import {
  useGetDriveItemsQuery,
  useGetDriveItemThumbnailsQuery,
  useGetDriveQuery,
  useGetSiteQuery,
  useLazyGetDriveItemsQuery,
} from "../../redux/slices/graphApiSlice";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Checkbox,
  CircularProgress,
  Divider,
  Icon,
  IconButton,
  ListItem,
  MenuItem,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  CheckCircle,
  Delete as DeleteIcon,
  Done,
  Edit as EditIcon,
  ErrorOutline,
  Folder as FolderIcon,
  MoreVert as MoreVertIcon,
  OpenInNew,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { IFolder } from "../../types/folder";
import StyledMenu from "../../theme/StyledMenu";
import MenuItemAsLabel from "../../theme/MenuItemAsLabel";
import { useDeleteFolderMutation } from "../../redux/slices/indexApiSlice";

type FolderCardProps = {
  folder: IFolder;
};

const FolderCard = ({ ...props }: FolderCardProps) => {
  const { t } = useTranslation();

  const folder = props.folder;

  const [
    deleteFolder,
    { isLoading: isDeleting, isSuccess: isDeleted, isError: errorOccurred },
  ] = useDeleteFolderMutation();

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirmDelete = async (e: any) => {
    e.stopPropagation();

    // Call the delete function to delete the item
    if (props.folder?.id) {
      deleteFolder(props.folder.id);
    }

    // Hide the confirmation
    setShowConfirmation(false);
  };

  const handleCancelDelete = (e: any) => {
    e.stopPropagation();
    // Hide the confirmation
    setShowConfirmation(false);

    // Close the menu
    handleClose();
  };

  return (
    <Card
      style={{
        filter:
          (isDeleting || isDeleted) && !errorOccurred ? "blur(3px)" : "none",
        opacity: (isDeleting || isDeleted) && !errorOccurred ? "0.5" : "1",
        transition: "filter 0.8s ease-in-out, opacity 0.8s ease-in-out",
        minWidth: 300,
      }}
    >
      <CardHeader
        avatar={<Checkbox />}
        action={
          <>
            <IconButton
              aria-label="more"
              id={`folder-menubutton-${folder?.id}`}
              aria-controls={open ? `folder-menu-${folder?.id}` : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              sx={{ ":hover": { visibility: "visible" } }}
            >
              <MoreVertIcon />
            </IconButton>
            <StyledMenu
              id={`message-menu-${folder?.id}`}
              MenuListProps={{
                "aria-labelledby": `folder-menubutton-${folder?.id}`,
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem disableRipple>
                <EditIcon />
                {t("Edit")}
              </MenuItem>

              <Divider sx={{ my: 0.5 }} />

              <MenuItem onClick={() => setShowConfirmation(true)} disableRipple>
                <DeleteIcon />
                {t("Delete")}
              </MenuItem>

              {showConfirmation && (
                <>
                  <MenuItemAsLabel sx={{ justifyContent: "center" }}>
                    Are you sure?
                  </MenuItemAsLabel>

                  {/* <Fade in={showConfirmation}> */}
                  <MenuItemAsLabel>
                    <Button
                      color="primary"
                      size="small"
                      onClick={handleConfirmDelete}
                    >
                      Yes, delete!
                    </Button>
                    <Button
                      color="primary"
                      size="small"
                      onClick={handleCancelDelete}
                    >
                      Cancel
                    </Button>
                  </MenuItemAsLabel>
                  {/* </Fade> */}
                </>
              )}
            </StyledMenu>
          </>
        }
      />

      {/* {image ? <CardMedia image={image} title="Contemplative Reptile" /> : null} */}
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <FolderIcon sx={{ fontSize: 96 }} />

        <Typography gutterBottom variant="h5" component="h2">
          {folder?.name}
        </Typography>
        {/* 
        <Typography mb={4} color="textSecondary" component="p">
          {drive?.name}
        </Typography>

        <Typography mb={4} color="textSecondary" component="p">
          {site?.webUrl}
        </Typography> */}
      </CardContent>
    </Card>
  );
};

export default FolderCard;
