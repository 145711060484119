import React, { useState, useEffect, ReactNode } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Avatar,
  AvatarGroup as MuiAvatarGroup,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Typography as MuiTypography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  FormControl as MuiFormControl,
  Paper as MuiPaper,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  IconButton,
  CardMedia as MuiCardMedia,
  CardActionArea,
  CardActions,
  Chip as MuiChip,
  Tooltip,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import {
  Send as SendIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { spacing, SpacingProps } from "@mui/system";
import {
  orange,
  green,
  blue,
  pink,
  deepOrange,
  deepPurple,
} from "@mui/material/colors";
import { Add as AddIcon, Delete, Spa } from "@mui/icons-material";
import {
  useGetDriveItemThumbnailsQuery,
  useGetDriveItemsQuery,
  useGetDriveQuery,
  useGetDrivesQuery,
  useGetFollowedSitesQuery,
  useGetSiteQuery,
  useSendMessageToQueueMutation,
} from "../../../redux/slices/graphApiSlice";
import {
  useAddContractMutation,
  useAddDocumentMutation,
  useAddSharePointConnectionMutation,
  useDeleteSharePointConnectionMutation,
  useGetFoldersQuery,
  useGetSharePointConnectionsQuery,
} from "../../../redux/slices/indexApiSlice";
import useAuth from "../../../hooks/useAuth";
import { IDocument } from "../../../types/document";
import { useTranslation } from "react-i18next";
import SharePointConnectionDialog from "../../../components/dialogs/SharePointConnectionDialog";
import { DialogMode } from "../../../types/dialogmode";
import { ISharePointConnection } from "../../../types/sharepointconnection";
import StyledMenu from "../../../theme/StyledMenu";
import MenuItemAsLabel from "../../../theme/MenuItemAsLabel";
import SharePointFilesDialog from "../../../components/dialogs/SharePointFilesDialog";
import AnalyzingContractsLoader from "../../../components/AnalyzingContractsLoader";
import { IFolder } from "../../../types/folder";
import FolderCard from "../../../components/cards/FolderCard";
import FolderDialog from "../../../components/dialogs/FolderDialog";

const Paper = styled(MuiPaper)(spacing);

const FormControlSpacing = styled(MuiFormControl)(spacing);

const FormControl = styled(FormControlSpacing)<{ m?: number }>`
  min-width: 148px;
`;

const PinkAvatar = styled(Avatar)`
  background-color: ${pink[500]};
`;

const GreenAvatar = styled(Avatar)`
  background-color: ${green[500]};
`;

const OrangeAvatar = styled(Avatar)`
  background-color: ${deepOrange[500]};
`;

const PurpleAvatar = styled(Avatar)`
  background-color: ${deepPurple[500]};
`;

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
`;

const Chip = styled(MuiChip)<{ color?: string }>`
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
  margin-bottom: ${(props) => props.theme.spacing(4)};
`;

const AvatarGroup = styled(MuiAvatarGroup)`
  margin-left: ${(props) => props.theme.spacing(2)};
`;

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

interface TypographyProps extends SpacingProps {
  component?: string;
}
const Typography = styled(MuiTypography)<TypographyProps>(spacing);

const Spacer = styled.div(spacing);

interface SharePointConnectionProps {
  connection: ISharePointConnection;
}

const CardMedia = styled(MuiCardMedia)`
  // height: auto; // 220px
`;

function Folders() {
  const { t } = useTranslation();

  const { data: folders } = useGetFoldersQuery();

  return (
    <React.Fragment>
      <Helmet title={t("Folders")!} />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid>
          <Typography variant="h3" gutterBottom display="inline">
            {t("Folders")}
          </Typography>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container direction="column" gap={6}>
        <Grid>
          <AnalyzingContractsLoader />
        </Grid>

        <Grid>
          <FolderDialog mode={DialogMode.Add} />
        </Grid>
        <Grid>
          <Grid container spacing={6}>
            {folders?.map((folder: IFolder, index: number) => (
              <Grid key={`folder-${folder.id}`} size={{ xs: 12, md: 4 }}>
                <FolderCard folder={folder} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Folders;
