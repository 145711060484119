import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  Divider as MuiDivider,
  LinearProgress as MuiLinearProgress,
  Typography as MuiTypography,
  Container,
  Pagination as MuiPagination,
  Paper as MuiPaper,
  CircularProgress as MuiCircularProgress,
  makeStyles,
  Tooltip,
  IconButton,
  Fade,
  CardMedia as MuiCardMedia,
  CardActionArea,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField as MuiTextField,
  Alert as MuiAlert,
  ListItem,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
  Popper,
  ClickAwayListener,
  Grow,
  StepContent,
  useMediaQuery,
  Theme,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { display, fontWeight, spacing, SpacingProps } from "@mui/system";

import {
  useDeleteDocumentMutation,
  useDeleteNoteMutation,
  useUpdateNoteMutation,
} from "../../../redux/slices/indexApiSlice";
import { IDocument } from "../../../types/document";

import { useTranslation } from "react-i18next";

import { INote } from "../../../types/note";
import { IGraphUser } from "../../../types/user";
import AzureADAvatar from "../../avatars/AzureADAvatar";
import DateTimeTranslate from "../../DateTimeTranslate";
import StyledMenu from "../../../theme/StyledMenu";
import NoteDialog from "../../dialogs/NoteDialog";
import { DialogMode } from "../../../types/dialogmode";
import {
  Alarm,
  AlarmOff,
  AlarmOn,
  Note,
  NoteAdd,
  NoteAlt,
  Folder as FolderIcon,
} from "@mui/icons-material";
import useAuth from "../../../hooks/useAuth";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { IFolder } from "../../../types/folder";

interface TypographyProps extends SpacingProps {
  component?: string;
}
const Typography = styled(MuiTypography)<TypographyProps>(spacing);

const Title = styled(Typography)`
  color: ${(props) => props.theme.palette.text.secondary};
  font-size: ${(props) => props.theme.typography.caption.fontSize};
  padding: ${(props) => props.theme.spacing(4)}
    ${(props) => props.theme.spacing(7)} ${(props) => props.theme.spacing(1)};
  opacity: 0.8;
  text-transform: uppercase;
  display: block;
`;

interface IFolderAsListItemProps {
  folder: IFolder;
  showMenuIcon?: boolean;
  onMouseEnter?: () => void;
}

const FolderAsListItem = (props: IFolderAsListItemProps) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const { folder } = props;

  const users = useSelector((state: RootState) => state.users.users);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ListItem
      onMouseEnter={props.onMouseEnter}
      // secondaryAction={
      //   <>
      //     <IconButton
      //       aria-label="more"
      //       id={`folder-menubutton-${folder.id}`}
      //       aria-controls={open ? `folder-menu-${folder.id}` : undefined}
      //       aria-expanded={open ? "true" : undefined}
      //       aria-haspopup="true"
      //       onClick={handleClick}
      //       sx={{ visibility: props.showMenuIcon ? "visible" : "hidden" }}
      //     >
      //       <MoreVertIcon />
      //     </IconButton>
      //     <StyledMenu
      //       id={`folder-menu-${folder.id}`}
      //       MenuListProps={{
      //         "aria-labelledby": `folder-menubutton-${folder.id}`,
      //       }}
      //       anchorEl={anchorEl}
      //       open={open}
      //       onClose={handleClose}
      //     >
      //       {/* <Divider sx={{ my: 0.5 }} /> */}

      //       {/* <FolderDialog
      //         mode={DialogMode.Edit}
      //         type={note.reminderDate ? "reminder" : "note"}
      //         note={props.note}
      //         asMenuItem
      //       /> */}

      //       {/* <NoteDialog
      //         mode={DialogMode.Delete}
      //         type={note.reminderDate ? "reminder" : "note"}
      //         note={props.note}
      //         asMenuItem
      //       /> */}
      //     </StyledMenu>
      //   </>
      // }
    >
      <ListItemAvatar>
        <Avatar>
          <FolderIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={folder.name} secondary={null} />
    </ListItem>
  );
};

export default FolderAsListItem;
