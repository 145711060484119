import styled from "@emotion/styled";
import { ArrowForwardIosRounded, SendRounded } from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Fab,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import React, { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import AzureADAvatar from "../avatars/AzureADAvatar";
import { IChatBotHistory } from "../../types/chatBot";
import { convertCreatedDate } from "../../utils/string";
import { useLazyGetChatBotHistoryQuery } from "../../redux/slices/indexApiSlice";
import UserAvatar from "../avatars/UserAvatar";
import { t, use } from "i18next";
import { lighten } from "polished";
import OwlIcon from "../../icons/OwlIcon";
import OwlAvatarIcon from "../../icons/OwlAvatarIcon";
import { useParams } from "react-router-dom";
import ContractAvatar from "../avatars/ContractAvatar";
import VendorAvatar from "../avatars/VendorAvatar";

const ChatDialog = styled(ListItemButton)`
  // padding-top: ${(props) => props.theme.spacing(3)};
  // padding-bottom: ${(props) => props.theme.spacing(3)};
  // padding-left: ${(props) => props.theme.spacing(5)};
  // padding-right: ${(props) => props.theme.spacing(5)};

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

// Main Component
type ChatHistoryItemProps = {
  item: IChatBotHistory;
  onClick: (chatBotHistory: IChatBotHistory) => any;
  onChange: (chatBotHistory: IChatBotHistory, checked: boolean) => any;
};

const ChatHistoryItem: React.FC<ChatHistoryItemProps> = ({
  item,
  onClick,
  onChange,
}) => {
  const handleListItemChecked = (e: any, checked: boolean) => {
    e.stopPropagation();

    if (onChange) {
      onChange(item, checked);
    }
  };

  return (
    <ListItem secondaryAction={<ArrowForwardIosRounded color="primary" />}>
      <ListItemButton
        // px={8}
        // py={4}
        // key={chatBotHistory.id}
        onClick={() => onClick(item)}
      >
        {/* <ListItemAvatar>
        <OwlAvatarIcon />
      </ListItemAvatar> */}

        <ListItemIcon>
          <Checkbox
            edge="start"
            // checked={checked.indexOf(value) !== -1}
            tabIndex={-1}
            disableRipple
            // inputProps={{ "aria-labelledby": labelId }}
            onClick={(e) => e.stopPropagation()}
            onChange={handleListItemChecked}
          />
        </ListItemIcon>

        <ListItemText>
          <Typography variant="body1">{item.content[0].message}</Typography>
          <Typography variant="caption">
            {convertCreatedDate(item.content[0].createdAt)}
          </Typography>
        </ListItemText>

        {item.contractId && (
          <Chip
            sx={{ mr: 12, maxWidth: "calc(100% - 400px)" }}
            label={<ContractAvatar contractId={item.contractId} small />}
            color="info"
          />
        )}

        {item.vendorId && (
          <Chip
            sx={{ mr: 12, maxWidth: "calc(100% - 400px)" }}
            label={<VendorAvatar vendorId={item.vendorId} small />}
            color="info"
          />
        )}
      </ListItemButton>
    </ListItem>
  );
};

export default ChatHistoryItem;
