import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { blue, grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import {
  Box,
  Chip as MuiChip,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  StepButton,
  Typography,
} from "@mui/material";

import { IContract } from "../../../../types/contract";

interface IContractTypeProps {
  contract?: IContract;
  hideTitle?: boolean;
}
function ContractType({ ...props }: IContractTypeProps) {
  const { contract } = props;
  const { t } = useTranslation();

  return (
    <Box pb={2}>
      {!props.hideTitle && (
        <Typography variant="h6" mb={2}>
          {t("Type")}
        </Typography>
      )}

      {contract?.type && (
        <Typography variant="body2">
          {/* color="textSecondary" */}
          {t(contract.type)}
        </Typography>
      )}
    </Box>
  );
}

export default ContractType;
