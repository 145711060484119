import {
  MenuBook,
  ListAlt,
  Store,
  AssuredWorkload,
  Home,
  FolderCopy,
  Folder,
  Business,
  BusinessCenter,
  AddBusiness,
  CalendarMonth,
  CollectionsBookmark,
  CloudQueue,
  Chat,
  AdminPanelSettings,
  Score,
  Percent,
  Dashboard,
  Speed,
  SpaceDashboard,
  ManageSearch,
  NextWeek,
} from "@mui/icons-material";
import { SidebarItemsType } from "../../types/sidebar";

import { useTranslation } from "react-i18next";
import { ContractStatus } from "../../types/contract";

const pagesSection = [
  {
    href: "/",
    icon: Home,
    title: "Home page",
  },
  {
    href: "/dashboard",
    icon: Dashboard,
    title: "Dashboard",
    children: [
      {
        href: "/dashboard/default",
        title: "Default",
        icon: SpaceDashboard,
      },
      {
        href: "/dashboard/analytics",
        title: "Analytics",
        icon: Speed,
      },
      // {
      //   href: "/dashboard/saas",
      //   title: "SaaS",
      // },
    ],
  },

  {
    href: "/calendar",
    icon: CalendarMonth,
    title: "Calendar",
  },
  {
    href: "/strength-matrix",
    icon: Percent,
    title: "Strength matrix",
  },
  // {
  //   href: "/tasks",
  //   icon: CheckSquare,
  //   title: "Tasks",
  //   badge: "17",
  // },

  // {
  //   href: "/auth",
  //   icon: Users,
  //   title: "Auth",
  //   children: [
  //     {
  //       href: "/auth/sign-in",
  //       title: "Sign In",
  //     },
  //     {
  //       href: "/auth/sign-up",
  //       title: "Sign Up",
  //     },
  //     {
  //       href: "/auth/reset-password",
  //       title: "Reset Password",
  //     },
  //     {
  //       href: "/auth/404",
  //       title: "404 Page",
  //     },
  //     {
  //       href: "/auth/500",
  //       title: "500 Page",
  //     },
  //   ],
  // },
] as any; // SidebarItemsType[];

const contractsSection = [
  {
    href: "/contracts",
    icon: ListAlt,
    title: "Contracts",
    children: [
      {
        href: `/contracts/${ContractStatus.ALL}`,
        title: ContractStatus.ALL,
        icon: Folder,
      },
      {
        href: `/contracts/${ContractStatus.DRAFT}`,
        title: ContractStatus.DRAFT,
        icon: Folder,
      },
      {
        href: `/contracts/${ContractStatus.PUBLISHED}`,
        title: ContractStatus.PUBLISHED,
        icon: Folder,
      },
      {
        href: `/contracts/${ContractStatus.SIGNING}`,
        title: ContractStatus.SIGNING,
        icon: Folder,
      },
      {
        href: `/contracts/${ContractStatus.SIGNED}`,
        title: ContractStatus.SIGNED,
        icon: Folder,
      },
      {
        href: `/contracts/${ContractStatus.ACTIVE}`,
        title: ContractStatus.ACTIVE,
        icon: Folder,
      },
      {
        href: `/contracts/${ContractStatus.EXPIRED}`,
        title: ContractStatus.EXPIRED,

        icon: Folder,
      },
      {
        href: `/contracts/${ContractStatus.ARCHIVED}`,
        title: ContractStatus.ARCHIVED,
        icon: Folder,
      },
    ],
  },
  {
    href: "/contracts-under-management",
    icon: FolderCopy, // AssuredWorkload,
    title: "Managed Contracts",
    hidden: true,
  },
];

const vendorsSection = [
  {
    href: "/vendors",
    icon: Store,
    title: "Vendors",
  },
];

const librariesSection = [
  {
    href: "/libraries",
    icon: CollectionsBookmark,
    title: "Libraries",
    children: [
      // {
      //   href: "/libraries/kpi",
      //   title: "Key Performance Indicators",
      // },
      {
        href: "/libraries/prompts",
        icon: Chat,
        title: "Prompts",
      },
      // {
      //   href: "/libraries/contract-template",
      //   title: "Contract Templates",
      // },
    ],
  },
] as SidebarItemsType[];

const configurationSection = [
  {
    href: "/connections",
    icon: CloudQueue,
    title: "Connections",
    children: [
      {
        href: "/connections/sharepoint",
        title: "SharePoint",
      },
      // {
      //   href: "/connections/msteams",
      //   title: "MS Teams",
      // },
      {
        href: "/connections/outlook",
        title: "Outlook",
      },
      // {
      //   href: "/connections/dynamics",
      //   title: "Dynamics",
      // },
      // {
      //   href: "/connections/salesforce",
      //   title: "SalesForce",
      // },
    ],
  },
] as SidebarItemsType[];

const adminSection = [
  {
    href: "/admin",
    icon: AdminPanelSettings,
    title: "Admin",
    children: [
      {
        href: "/admin/activitylog",
        title: "Activity Log",
      },
      {
        href: "/admin/sharepoint-connections",
        title: "SharePoint Connections",
      },
      {
        href: "/admin/trusted-partners",
        title: "Trusted Partners",
      },
      {
        href: "/admin/data-location",
        title: "Data Location",
      },
      {
        href: "/admin/connected-drives",
        title: "Connected Drives",
      },
      {
        href: "/admin/folders",
        title: "Folders",
      },
      {
        href: "/settings",
        title: "Settings",
      },
    ],
  },
] as SidebarItemsType[];

const navItems = [
  {
    title: "Pages",
    pages: pagesSection,
  },
  {
    title: "Contracts",
    pages: contractsSection,
  },
  {
    title: "Managed Contracts",
    pages: [
      {
        href: "/contracts-under-management",
        icon: NextWeek, // AssuredWorkload,
        title: "Managed Contracts",
        hidden: true,
      },
    ],
  },
  {
    title: "Vendors",
    pages: vendorsSection,
  },
  {
    title: "Libraries",
    pages: librariesSection,
  },
  // {
  //   title: "Configuration",
  //   pages: configurationSection,
  // },
  {
    title: "Admin",
    pages: adminSection,
  },
  // {
  //   title: "Elements",
  //   pages: elementsSection,
  // },
  // {
  //   title: "Mira Pro",
  //   pages: docsSection,
  // },
];

export default navItems;
