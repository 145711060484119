import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Bar } from "react-chartjs-2";

import { IFeedback } from "../../../types/feedback";
import { add, format, set } from "date-fns";
import { nl, enGB } from "date-fns/locale";

import ShowMoreText from "react-show-more-text";

import {
  Briefcase,
  DollarSign,
  ExternalLink,
  Eye,
  Facebook,
  Home,
  Instagram,
  MapPin,
  PhoneCall,
  ShoppingBag,
  Twitter,
} from "react-feather";
import {
  Add as AddIcon,
  NoteAdd as NoteAddIcon,
  Delete as DeleteIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  EditNote as EditNoteIcon,
  EventAvailable,
  EventBusy,
  Check,
  Savings as SavingsIcon,
  Edit as EditIcon,
  Feed,
  Phone,
  ChevronLeft,
  ChevronRight,
  Mail,
  CrisisAlert,
  Fastfood,
  Event,
  CheckBox,
  NewReleases,
  Verified,
  Eject as EjectIcon,
  Launch as LaunchIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Style,
  AutoFixHigh,
  MoreHoriz as MoreHorizIcon,
  HistoryToggleOff,
  History,
  Task,
  Download as DownloadIcon,
  AutoFixOff,
  Remove,
  Cancel,
  Close,
  CancelOutlined,
} from "@mui/icons-material";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  Divider as MuiDivider,
  LinearProgress as MuiLinearProgress,
  Typography as MuiTypography,
  Container,
  Pagination as MuiPagination,
  Paper as MuiPaper,
  CircularProgress as MuiCircularProgress,
  IconButton,
  TextField as MuiTextField,
  Alert as MuiAlert,
  ListItem,
  ListItemText,
  ListItemButton,
} from "@mui/material";

import { fontWeight, spacing, SpacingProps } from "@mui/system";

import { useTranslation } from "react-i18next";

import {
  useGetDriveItemPreviewMutation,
  useLazyGetOutlookMessageAttachmentContentQuery,
  useLazyGetOutlookMessageAttachmentQuery,
} from "../../../redux/slices/graphApiSlice";
import { DialogMode } from "../../../types/dialogmode";
import { IVendor } from "../../../types/vendor";

const LinearProgress = styled(MuiLinearProgress)(spacing);

interface TypographyProps extends SpacingProps {
  component?: string;
}
const Typography = styled(MuiTypography)<TypographyProps>(spacing);

const Title = styled(Typography)`
  color: ${(props) => props.theme.palette.text.secondary};
  font-size: ${(props) => props.theme.typography.caption.fontSize};
  padding: ${(props) => props.theme.spacing(4)}
    ${(props) => props.theme.spacing(7)} ${(props) => props.theme.spacing(1)};
  opacity: 0.8;
  text-transform: uppercase;
  display: block;
`;

type IVendorAsListItemProps = {
  vendor: IVendor;
  onClick: () => void;
  onDelete?: () => void;
  uploading?: boolean;
  uploaded?: boolean;
  uploadPercentProgress?: number;
};

const VendorAsListItem = (props: IVendorAsListItemProps) => {
  const { t } = useTranslation();

  const {
    vendor,
    onClick,
    onDelete,
    uploading,
    uploaded,
    uploadPercentProgress,
  } = props;

  const [isDeleting, setIsDeleting] = useState(false);

  const [getDriveItemPreview, { data: driveItemPreview }] =
    useGetDriveItemPreviewMutation();

  const [getOutlookAttachment, { data: outlookAttachment }] =
    useLazyGetOutlookMessageAttachmentQuery();

  const [getOutlookAttachmentContent, { data: outlookAttachmentContent }] =
    useLazyGetOutlookMessageAttachmentContentQuery();

  const handleDeleteClick = () => {
    if (onDelete) {
      setIsDeleting(true);
      onDelete();
    }
  };

  return (
    <ListItem
      secondaryAction={
        <>
          <IconButton
            // onClick={() => viewFile()}
            title={t("Open")!}
            color="info"
            size="small"
          >
            <Eye />
          </IconButton>

          {onDelete && (uploaded || uploadPercentProgress === 0) && (
            <IconButton
              onClick={() => handleDeleteClick()}
              title={t("Delete")!}
              color="info"
              size="small"
            >
              {/* <DeleteIcon /> */}
              <CancelOutlined />
            </IconButton>
          )}
        </>
      }
    >
      <ListItemButton
        onClick={onClick}
        color="secondary"
        sx={{ paddingRight: `${48 * 3}px` }}
        disabled={isDeleting}
      >
        <ListItemText>
          <Typography
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={vendor?.name}
          >
            {vendor?.name}
          </Typography>

          <Typography
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={vendor?.kvkNumber}
          >
            {vendor?.kvkNumber}
          </Typography>

          {(uploading || uploaded) && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ width: "100%", mr: 1 }}>
                <LinearProgress
                  variant="determinate"
                  value={uploadPercentProgress}
                  color={uploadPercentProgress === 100 ? "success" : "primary"}
                />
              </Box>
              <Box
                sx={{
                  minWidth: 35,
                  padding: 0,
                  height: 28,
                  alignContent: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {uploadPercentProgress === 100 ? (
                  <Check color="success" />
                ) : (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      marginLeft: 1,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >{`${Math.round(uploadPercentProgress!)}%`}</Typography>
                )}
              </Box>
            </Box>
          )}
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
};

export default VendorAsListItem;
